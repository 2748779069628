<template>
  <div class="myTeamBox">
    <!-- 头部开始 -->
    <div class="bgc">
      <div class="topTitle">{{ $t("myTeam.default[0]") }}</div>
    </div>
    <!-- 头部结束 -->
    <div class="height"></div>
    <div class="myTeam_nav">
      <div class="myTeam_nav_box">
        <div class="myTeam_nav_box_title">{{ $t("myTeam.default[5]") }}</div>
        <ul class="myTeam_nav_box_box">
          <li
            class="myTeam_nav_box_item"
            :class="{ active: index == registerType-1 }"
            v-for="(item, index) in $t('myTeam.nav')"
            :key="index"
            @click="registerType = index+1"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="myTeam_inp_box">
        <div class="myTeam_nav_box_title">{{ $t("myTeam.default[2]") }}</div>
        <input class="myTeam_inp_box_inp" v-model="userAccount" />
        <div class="myTeam_inp_box_btn" @click="getTeamSummary()">
          {{ $t("myTeam.default[3]") }}
        </div>
      </div>
    </div>
    <div class="myTeam_content">
      <div
        class="myTeam_content_item"
        v-for="(item, index) in teamSummaryList"
        :key="index"
      >
        <div class="myTeam_content_info">
          <div class="myTeam_content_info_title">
            {{ $t("myTeam.default[2]") }}: <span>{{ item.username }}</span>
          </div>
          <!--  <div class="myTeam_content_vip">LV{{ item.vip_level }}</div> -->
        </div>
        <div class="myTeam_content_subordinate">
          <div class="myTeam_content_subordinate_count">
            {{ $t("myTeam.default[4]") }}<span>{{ item.state }}</span>
          </div>
          <div class="myTeam_content_time">
            {{ $t("myTeam.default[5]") }}:
            <span style="transform: scale(0.9);">{{
              getRegTime(item.reg_time)
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "myTeam",
  components: {},
  data() {
    return {
      registerType: 4, //注册时间类型：今天1，本周2，本月3，全部4
      userAccount: "", //搜索输入用户账号
      teamSummaryList: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    //标题
    this.$parent.navBarTitle = "";
    //默认显示团队 全部
    this.getTeamSummary({ registerType: this.registerType });
  },
  methods: {
    getRegTime(time) {
      var dd = new Date(time*1000);
      return this.$Util.DateFormat("YY-MM-DD", dd);
    },
    //查询我的团队信息
    getTeamSummary() {
      var param = {
        registerType: this.registerType,
      };
      if (this.userAccount&&this.userAccount.length>0) {
        param['username'] = this.userAccount;
      }

      this.$Model.getTeamData(param, (res) => {
        this.teamSummaryList = res.teamData;
      });
    },
  },
};
</script>

<style scoped>
.myTeamBox {
  overflow: auto;
  height: 100vh;
  background-color: #f5f5f5;
}
.myTeamBox .bgc {
  width: 100%;
  background-color: #4e51bf;
  height: 46px;
  position: fixed;
  top: 0;
  z-index: 200;
}

.topTitle {
  position: absolute;
  max-width: 60%;
  white-space: nowrap;
  font-size: 18px;
  color: #fff;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.height {
  height: 46px;
}
.myTeam_nav {
  padding: 20px 20px 100px;
  background-color: #4e51bf;
  border-radius: 0px 0px 80px 80px;
}
.myTeam_nav .myTeam_nav_box {
  max-width: 520px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.myTeam_nav .myTeam_nav_box_title {
  color: #f5f5ff;
  font-size: 13px;
}
.myTeam_nav .myTeam_nav_box_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;
}
.myTeam_nav .myTeam_nav_box_item {
  border: 1px solid #7575d3;
  border-radius: 4px;
  color: #fff;
  font-size: 13px;
  line-height: 1;
  flex-shrink: 0;
  width: 62px;
  height: 30px;
  text-align: center;
  line-height: 2;
  background-color: #4143ac;
  /* margin-bottom: 5px; */
  margin-left: 6.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.active {
  background: linear-gradient(180deg, #b1adff, #f3e1d6) !important;
  color: #403dec !important;
}
.myTeam_nav .myTeam_inp_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  max-width: 400px;
}
.myTeam_nav .myTeam_inp_box_inp {
  flex: 1;
  margin-left: 5%;
  margin-right: 2%;
  height: 30px;
  border-radius: 6px;
  border: none;
  background-color: #4143ac;
  border: 1px solid #7575d3;
  min-width: 100px;
  padding: 0 10px;
  color: #fff;
}
.myTeam_nav .myTeam_inp_box_btn {
  padding: 8px 17px;
  border: 1px solid #afaeff;
  border-radius: 6px;
  color: #403dec;
  font-size: 13px;
  line-height: 1;
  background: linear-gradient(180deg, #b1adff, #f3e1d6);
  margin-left: auto;
  margin-top: 0px;
}
.myTeam_content {
  position: relative;
  top: -40px;
  padding: 20px;
  padding-bottom: 0;
  margin-top: -65px;
}
.myTeam_content .myTeam_content_item {
  padding: 10px 12px;
  background: #ffffff;
  border-radius: 16px;
  margin-bottom: 10px;
}
.myTeam_content .myTeam_content_item:last-child {
  margin-bottom: 0px;
}
.myTeam_content .myTeam_content_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.myTeam_content .myTeam_content_info_title {
  font-size: 14px;
  color: #282828;

}
.myTeam_content .myTeam_content_vip {
  background: #d9d8fb;

  padding: 5px 15px;
  font-size: 13px;
  border-radius: 18px;
  color: #8182d5;
}
.myTeam_content .myTeam_content_subordinate {
  margin-top: 10px;
  border-radius: 0px 40px 40px 40px;
  background-color: #ecebfd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 25px;
}
.myTeam_content .myTeam_content_subordinate_count {
  font-size: 11px;
  display: flex;
  /* font-weight: 600; */
  color: #595bc3;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.myTeam_content .myTeam_content_subordinate_count span {
  font-size: 18px;
  font-weight: bold;
  color: #4e51bf;
  display: inline-block;
  vertical-align: -2px;
  margin-left: 8px;
}
.myTeam_content .myTeam_content_time {
  font-size: 11px;
  color: #282828;
  /* font-weight: 600; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.myTeam_content .myTeam_content_time span {
  display: inline-block;
  margin-top: 3px;
}
.van-toast {
  display: none !important;
}
</style>
